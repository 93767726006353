Object.defineProperty(module.exports, "__esModule", {
  value: true
})

module.exports = {
  spacing100: 2,
  spacing200: 4,
  spacing300: 8,
  spacing400: 16,
  spacing450: 24,
  spacing500: 32,
  spacing600: 64,
  spacingNone: 0,
  spacingXxsmall: 2,
  spacingXsmall: 4,
  spacingSmall: 8,
  spacingMedium: 16,
  spacingLarge: 32,
  spacingXlarge: 64
};