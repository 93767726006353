"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "useTheme", {
  enumerable: true,
  get: function get() {
    return _theme.useTheme;
  }
});
Object.defineProperty(exports, "Style", {
  enumerable: true,
  get: function get() {
    return _theme.Style;
  }
});
exports["default"] = void 0;

require("./global-styles");

var _theme = _interopRequireWildcard(require("./theme"));

var _default = _theme["default"];
exports["default"] = _default;