Object.defineProperty(module.exports, "__esModule", {
  value: true
})

module.exports.default = function(tokens) {
  return {
    boxBackgroundColorOutline: tokens.themeBackgroundPrimaryDefault,
    boxBackgroundColorFill: tokens.themeBackgroundSecondaryDefault,
    boxBorderWidth: 1,
    boxBorderRadiusOutline: 4,
    boxBorderRadiusFill: 4,
    boxBorderColorOutline: tokens.themeBorderSecondaryDefault
  }
};