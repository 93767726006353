"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var lowerFirst = function lowerFirst(str) {
  return str ? str.charAt(0).toLowerCase() + str.slice(1) : "";
};

var _default = lowerFirst;
exports["default"] = _default;