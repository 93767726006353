"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var shim = function shim() {};

shim.isRequired = shim;

var getShim = function getShim() {
  return shim;
}; // Important!
// Keep this list in sync with production version in `./_prop-types.js`.


var size = shim;
var flexAlignment = getShim;
var spacing = getShim;
var alignment = shim;
var _default = {
  size: size,
  flexAlignment: flexAlignment,
  spacing: spacing,
  alignment: alignment
};
exports["default"] = _default;