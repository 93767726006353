Object.defineProperty(module.exports, "__esModule", {
  value: true
})

module.exports.default = function(tokens) {
  return {
    textFontFamilyAmazonEmber: "\"Amazon Ember\", Arial, sans-serif",
    textFontFamilyAmazonEmberDisplay: "\"Amazon Ember Display\", \"Amazon Ember\", Arial, sans-serif",
    textFontFamilyAmazonEmberMonospace: "\"Amazon Ember Monospace\", \"Courier New\", Courier, monospace",
    textFontFamilyBookerly: "Bookerly, Georgia, serif",
    textFontFamilyBookerlyDisplay: "\"Bookerly Display\", Georgia, serif",
    textFontSizeD300: 76,
    textFontSizeD200: 54,
    textFontSizeD100: 48,
    textFontSizeD50: 36,
    textFontSizeH600: 36,
    textFontSizeH500: 28,
    textFontSizeH400: 24,
    textFontSizeH300: 20,
    textFontSizeH200: 18,
    textFontSizeH100: 16,
    textFontSizeB500: 20,
    textFontSizeB400: 18,
    textFontSizeB300: 16,
    textFontSizeB200: 14,
    textFontSizeB100: 12,
    textFontWeightD300: 200,
    textFontWeightD200: 200,
    textFontWeightD100: 200,
    textFontWeightD50: 200,
    textFontWeightH600: 600,
    textFontWeightH500: 600,
    textFontWeightH400: 600,
    textFontWeightH300: 600,
    textFontWeightH200: 600,
    textFontWeightH100: 600,
    textFontWeightB500: 400,
    textFontWeightB400: 400,
    textFontWeightB300: 400,
    textFontWeightB200: 400,
    textFontWeightB100: 400,
    textLineHeightD300: "96px",
    textLineHeightD200: "64px",
    textLineHeightD100: "60px",
    textLineHeightD50: "48px",
    textLineHeightH600: "48px",
    textLineHeightH500: "36px",
    textLineHeightH400: "32px",
    textLineHeightH300: "24px",
    textLineHeightH200: "24px",
    textLineHeightH100: "20px",
    textLineHeightB500: "28px",
    textLineHeightB400: "28px",
    textLineHeightB300: "24px",
    textLineHeightB200: "20px",
    textLineHeightB100: "16px",
    textColorPrimary: tokens.themeForegroundPrimaryDefault,
    textColorSecondary: tokens.themeForegroundSecondaryDefault,
    textColorError: tokens.themeForegroundPrimaryError,
    textColorInverted: tokens.themeForegroundInvertedDefault,
    textListSpacingHorizontal: 20,
    textListSpacingVertical: 8
  }
};