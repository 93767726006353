Object.defineProperty(module.exports, "__esModule", {
  value: true
})

module.exports.default = function(tokens) {
  return {
    loaderIndicatorBackgroundColor: tokens.themeBorderAccentDefault,
    loaderIndicatorMotionIndeterminateDuration: "700ms",
    loaderIndicatorMotionIndeterminateFunction: "linear",
    loaderIndicatorMotionDeterminateDuration: "150ms",
    loaderIndicatorMotionDeterminateFunction: "linear",
    loaderTrackBackgroundColor: tokens.themeBackgroundTertiaryDefault,
    loaderTrackLinearLargeHeight: 8,
    loaderTrackLinearSmallHeight: 4,
    loaderTrackCircularLargeSize: 64,
    loaderTrackCircularLargeStrokeWidth: 4,
    loaderTrackCircularMediumSize: 32,
    loaderTrackCircularMediumStrokeWidth: 4,
    loaderTrackCircularSmallSize: 16,
    loaderTrackCircularSmallStrokeWidth: 2,
    loaderPercentageSpacing: 8,
    loaderBorderRadius: 999,
    loaderFontSizeLinear: 14,
    loaderFontSizeSmall: 12,
    loaderFontSizeMedium: 12,
    loaderFontSizeLarge: 14,
    loaderLineHeightLinear: "20px",
    loaderLineHeightSmall: "16px",
    loaderLineHeightMedium: "16px",
    loaderLineHeightLarge: "20px"
  }
};