"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _text = _interopRequireDefault(require("@amzn/meridian-tokens/component/text"));

var text = function text(t) {
  var keys = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var tokens = (0, _text["default"])(t.tokens);
  return {
    color: t("foregroundColor", keys.color) || t.tokens.themeForegroundPrimaryDefault,
    fontFamily: t("fontFamily", keys.fontFamily) || tokens.textFontFamilyAmazonEmber,
    fontSize: t("fontSize", keys.fontSize) || tokens.textFontSizeB300,
    fontWeight: t("fontWeight", keys.fontWeight) || tokens.textFontWeightB300,
    lineHeight: t("lineHeight", keys.lineHeight) || tokens.textLineHeightB300
  };
};

var _default = text;
exports["default"] = _default;