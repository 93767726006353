"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.memoizeTokenStyles = exports.removeTokenPrefixes = exports.getUnthemedTokens = exports.getIconFromTokens = exports.getTokenHelper = exports.getToken = void 0;

var _pascalCase = _interopRequireDefault(require("./pascal-case"));

var _upperFirst = _interopRequireDefault(require("./upper-first"));

var _lowerFirst = _interopRequireDefault(require("./lower-first"));

var _functional = require("./functional");

/**
 * Select a single token from a token object given a key (and optionally a
 * "suffix" key or list of suffix keys). If the token doesn't exist then an
 * attempt is made to replace the last suffix key with "default" and get the
 * value for that token. If that token also doesn't exist then `undefined` is
 * returned.
 *
 * @example
 * const tokens = {
 *   backgroundColorDefault: "#fff",
 *   backgroundColorHover: "#ccc",
 *   backgroundColorPressed: "#f00",
 * }
 * getToken(tokens, "backgroundColor", "active") // #fff
 * getTokens(tokens, "background", ["color", "pressed"]) // #f00
 * getToken(tokens, "myBackgroundColor") // undefined
 */
var getToken = function getToken(tokens, key, suffixKeys) {
  // If we got an array of suffix keys, turn them into a PascalCaseString and
  // look up the token
  if (suffixKeys && Array.isArray(suffixKeys)) {
    var value = tokens["".concat(key).concat((0, _pascalCase["default"])(suffixKeys))];
    return value !== undefined ? value : tokens["".concat(key).concat((0, _pascalCase["default"])(suffixKeys.slice(0, -1)), "Default")]; // If we got a single suffix key then upper case the first character and look
    // up the token
  } else if (suffixKeys) {
    var _value = tokens["".concat(key).concat((0, _upperFirst["default"])(suffixKeys))];
    return _value !== undefined ? _value : tokens["".concat(key, "Default")]; // If we didn't get any suffix keys then do a simple lookup
  } else {
    return tokens[key];
  }
};
/**
 * This will, given a root key and token object, return a special helper that
 * includes token utilities with those inputs already applied. Calling the
 * helper as a function is equivalent to calling `getToken`, but the helper also
 * includes several properties that make it easier to construct CSS from tokens.
 * This helper is useful when working with an object of tokens that all have the
 * same prefix.
 *
 * @example
 * const tokens = {
 *   buttonBorderWidth: 1,
 *   buttonBorderStyle: "dotted",
 *   buttonBorderColor: "#f00",
 * }
 * const t = getTokenHelper("button")(tokens)
 * t("borderWidth") // 1
 * t.borderCss("border") // "1px dotted #f00"
 * t.tokens === tokens // true
 *
 */


exports.getToken = getToken;
var getTokenHelper = (0, _functional.memoizeCurry)(function (prefixKey, tokens) {
  var helper = function helper(key, suffixKeys) {
    return getToken(tokens, prefixKey ? "".concat(prefixKey).concat((0, _upperFirst["default"])(key)) : key, suffixKeys);
  };

  helper.tokens = tokens; // Pass in "myBorder" and shorthand border css will be generated based on
  // "myBorderWidth" and "myBorderColor" tokens.

  helper.borderCss = function (key) {
    var width = helper(key, "width");
    var color = helper(key, "color");
    return width && color ? "".concat(width, "px solid ").concat(color) : undefined;
  }; // Pass in "myShadow" and shorthand box shadow css will be generated based on
  // "myShadowOffsetX", "myShadowOffsetY", "myShadowBlur", "myShadowSpread",
  // and "myShadowColor" tokens


  helper.boxShadowCss = function (key) {
    var color = helper(key, "color");
    return color ? "".concat(helper(key, "offsetX"), "px ").concat(helper(key, "offsetY"), "px ").concat(helper(key, "blur"), "px ").concat(helper(key, "spread"), "px ").concat(color) : undefined;
  }; // Pass in "myMotion" and a single css property or array of properties and
  // shorthand transition css will be generated that animates those properties
  // based on "myMotionFunction" and "myMotionDuration" tokens


  helper.transitionCss = function (key, properties) {
    var transition = "".concat(helper(key, "duration"), " ").concat(helper(key, "function"));
    return Array.isArray(properties) ? properties.map(function (property) {
      return "".concat(property, " ").concat(transition);
    }).join(", ") : "".concat(properties, " ").concat(transition);
  };

  return helper;
}, 1);
/**
 * Gets icon data from a set of icon tokens
 *
 * ```
 * const tokens = {
 *   iconCheckmarkData: "<svg />",
 *.  iconCheckmarkWidth: 12,
 *   iconCheckmarkHeight: 10,
 * }
 * // Returns { data: "<svg />", width: 12, height: 10 }
 * getIconFromTokens(tokens)
 * ```
 */

exports.getTokenHelper = getTokenHelper;

var getIconFromTokens = function getIconFromTokens(tokens) {
  return Object.keys(tokens).reduce(function (result, key) {
    if (key.indexOf("Data") > 0) {
      result.data = tokens[key];
    } else if (key.indexOf("Width") > 0) {
      result.width = tokens[key];
    } else if (key.indexOf("Height") > 0) {
      result.height = tokens[key];
    }

    return result;
  }, {});
};
/**
 * If you pass in a function that returns themed tokens when given a theme
 * object (e.g. any of our "component" tokens), this will call that function
 * with an empty theme object and filter out any tokens that are undefined. This
 * is useful for quickly accessing unthemed component tokens. Use this sparingly
 * as what is and is not themed may change in the future and require a refactor
 * of any code using this function.
 */


exports.getIconFromTokens = getIconFromTokens;

var getUnthemedTokens = function getUnthemedTokens(tokens) {
  var unthemedTokens = tokens({});
  return Object.keys(unthemedTokens).reduce(function (result, key) {
    if (unthemedTokens[key] !== undefined) {
      result[key] = unthemedTokens[key];
    }

    return result;
  }, {});
};
/**
 * Takes an object of tokens and removes the prefix from each token name (object
 * key). The result is returned with the first character of the new keys
 * lowercased.
 *
 * ```
 * const tokens = {
 *   iconCheckmarkData: "<svg />",
 *.  iconCheckmarkWidth: 12,
 *   iconCheckmarkHeight: 10,
 * }
 * // Returns { data: "<svg />", width: 12, height: 10 }
 * removetokenPrefixes(tokens, "iconCheckmark")
 * ```
 */


exports.getUnthemedTokens = getUnthemedTokens;

var removeTokenPrefixes = function removeTokenPrefixes(tokens, prefix) {
  var match = new RegExp("^".concat(prefix));
  return Object.keys(tokens).reduce(function (result, key) {
    if (match.test(key)) {
      result[(0, _lowerFirst["default"])(key.replace(match, ""))] = tokens[key];
    }

    return result;
  }, {});
};
/**
 * Memoize f(tokens, props) that is used extensively throughout meridian components,
 * specify the keys in the object to compare when determining whether to re-use
 * a previous calculation.
 *
 * @example
 * const style = memoizeTokenStyles((t, {a, b}) => css({a, b}), ["a", "b"])
 * style(tokens, {a, b})
 */


exports.removeTokenPrefixes = removeTokenPrefixes;

var memoizeTokenStyles = function memoizeTokenStyles(f, keys) {
  /* eslint-disable-next-line rulesdir/no-partial-memoization */
  var fn = (0, _functional.memoize)(function (t) {
    return (0, _functional.memoizeByKeys)(function (props) {
      return f(t, props);
    }, keys);
  });
  return function (t, props) {
    return fn(t)(props);
  };
};

exports.memoizeTokenStyles = memoizeTokenStyles;