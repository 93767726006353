import React from 'react';
import Column from '@amzn/meridian/column';
import Loader from '@amzn/meridian/loader';

export const CenteredLoader = () => (
  <Column height="100vh" alignmentHorizontal="center" alignmentVertical="center">
    <Loader type="circular" size="large" />
  </Column>
);

export default CenteredLoader;
