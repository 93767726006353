"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _upperFirst = _interopRequireDefault(require("./upper-first"));

var pascalCase = function pascalCase(strings) {
  return strings.map(_upperFirst["default"]).join("");
};

var _default = pascalCase;
exports["default"] = _default;