import React, { lazy, Suspense } from 'react';
import { render } from 'react-dom';
import '@amzn/meridian-tokens/base/font/amazon-ember.css';
import { configureStore, history } from './store/configureStore';
import CenteredLoader from './components/CenteredLoader';

const Root = lazy(() => import('./containers/Root'));

const store = configureStore();

render(
  <Suspense fallback={<CenteredLoader />}>
    <Root store={store} history={history} />
  </Suspense>,
  document.getElementById('root')
);
