"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/**
 * Takes an object of props and filters them down to only the props with names
 * that start with one of a given set of prefixes.
 */
var filterPropsByPrefix = function filterPropsByPrefix(props, prefixes) {
  return Object.keys(props).filter(function (name) {
    return prefixes.some(function (prefix) {
      return name.indexOf(prefix) === 0;
    });
  }).reduce(function (result, name) {
    result[name] = props[name];
    return result;
  }, {});
};

var _default = filterPropsByPrefix;
exports["default"] = _default;